




































import { Building, BuildingFilter, BuildingService, Client, ClientBuilder, Room, RoomFilter, RoomService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private id!: number;

    private client!: Client;

    private room: Room = new Room();

    private buildings: Building[] = [];

    async created(){
        this.id = Number.parseInt(this.$route.params.id);

        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        const filter = new RoomFilter();
        filter.ids = [];
        filter.ids.push(this.id);
        this.room = (await RoomService.Get(this.client, filter)).rooms[0];

        // Get list of buildings
        const response = await BuildingService.Get(this.client, new BuildingFilter());
        this.buildings = response.buildings;
    }

    async submit(){
        const response = await RoomService.Update(this.client, this.id, this.room);

        this.$router.go(0);
    }
}
