import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../pages/Home.vue'
import ListGroups from '../pages/groups/ListGroups.vue'
import CreateGroup from '../pages/groups/CreateGroup.vue'
import EditGroup from '../pages/groups/EditGroup.vue'
import ListBuildings from '../pages/buildings/ListBuildings.vue'
import CreateBuilding from '../pages/buildings/CreateBuilding.vue'
import EditBuilding from '../pages/buildings/EditBuilding.vue'
import ListRooms from '../pages/rooms/ListRooms.vue'
import CreateRoom from '../pages/rooms/CreateRoom.vue'
import EditRoom from '../pages/rooms/EditRoom.vue'
import ListComputers from '../pages/computers/ListComputers.vue'
import CreateComputer from '../pages/computers/CreateComputer.vue'
import EditComputer from '../pages/computers/EditComputer.vue'
import ListTasks from '../pages/tasks/ListTasks.vue'
import CreateTask from '../pages/tasks/CreateTask.vue'
import EditTask from '../pages/tasks/EditTask.vue'
import ListTysk from '../pages/tysk/ListTysk.vue'
import CreateTysk from '../pages/tysk/CreateTysk.vue'
import EditTysk from '../pages/tysk/EditTysk.vue'
import ListVehicles from '../pages/vehicles/ListVehicles.vue'
import CreateVehicle from '../pages/vehicles/CreateVehicle.vue'
import EditVehicle from '../pages/vehicles/EditVehicle.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '/Groups/',
        name: 'ListGroups',
        component: ListGroups
    },
    {
        path: '/Groups/Create/',
        name: 'CreateGroup',
        component: CreateGroup
    },
    {
        path: '/Groups/:id/edit/',
        name: 'EditGroup',
        component: EditGroup
    },

    {
        path: '/Buildings/',
        name: 'ListBuildings',
        component: ListBuildings
    },
    {
        path: '/Buildings/Create/',
        name: 'CreateBuilding',
        component: CreateBuilding
    },
    {
        path: '/Buildings/:id/edit/',
        name: 'EditBuilding',
        component: EditBuilding
    },

    {
        path: '/Rooms/',
        name: 'ListRooms',
        component: ListRooms
    },
    {
        path: '/Rooms/Create/',
        name: 'CreateRoom',
        component: CreateRoom
    },
    {
        path: '/Rooms/:id/edit/',
        name: 'EditRoom',
        component: EditRoom
    },

    {
        path: '/Computers/',
        name: 'ListComputers',
        component: ListComputers
    },
    {
        path: '/Computers/Create/',
        name: 'CreateComputer',
        component: CreateComputer
    },
    {
        path: '/Computers/:serviceTag/edit/',
        name: 'EditComputer',
        component: EditComputer
    },

    {
        path: '/Tasks/',
        name: 'ListTasks',
        component: ListTasks
    },
    {
        path: '/Tasks/Create/',
        name: 'CreateTask',
        component: CreateTask
    },
    {
        path: '/Tasks/:id/edit/',
        name: 'EditTask',
        component: EditTask
    },

    {
        path: '/Tysk/',
        name: 'ListTysk',
        component: ListTysk
    },
    {
        path: '/Tysk/Create/',
        name: 'CreateTysk',
        component: CreateTysk
    },
    {
        path: '/Tysk/:id/edit/',
        name: 'EditTysk',
        component: EditTysk
    },

    {
        path: '/Vehicles/',
        name: 'ListVehicles',
        component: ListVehicles
    },
    {
        path: '/Vehicles/Create/',
        name: 'CreateVehicle',
        component: CreateVehicle
    },
    {
        path: '/Vehicles/:id/edit/',
        name: 'EditVehicle',
        component: EditVehicle
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
