























































import { ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Nav extends Vue
{
    private baseUrl = process.env.BASE_URL;

    // Authentication URL redirecting back to root of LS Web
    private redirectUrl = process.env.VUE_APP_SERVER_URL + '/authentication/loginslack?returnUrl=' + process.env.VUE_APP_AUTH_RETURN_URL;

    async created(): Promise<void> {
        // See if the user is authenticated
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        const client = clientBuilder.openSession("");
        const authStatus = await client.isAuthenticated();
        this.$store.commit('setIsAuthenticated', authStatus);
    }
}
