import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import '@/components'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js';

Vue.config.productionTip = false
Vue.use(Vuex)

// Global store
const store = new Vuex.Store({
    state: {
        isAuthenticated: false,
    },
    mutations: {
        setIsAuthenticated(state, b: boolean) {
            state.isAuthenticated = b
        }
    }
})

// Create and mount Vue app
new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app')
