




























import { Vehicle, VehicleService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class extends Vue
{
    private client!: Client;

    private vehicle: Vehicle = new Vehicle();

    created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        // Set select to "Select an owner"
        this.vehicle.ownedBy = -1;
    }

    async submit(){
        const response = await VehicleService.Create(this.client, this.vehicle);

        this.$router.push({name: "EditVehicle", params :{ id: "" + response.id }});
    }
}
