






































import { Building, Client, ClientBuilder, Computer, ComputerFilter, ComputerService, Room, RoomFilter, RoomIncludes, RoomService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private serviceTag!: string;

    private client!: Client;

    private computer: Computer = new Computer();

    private rooms: Room[] = [];
    private buildings: Building[] = [];

    async created(){
        // Get serviceTag from route
        this.serviceTag = this.$route.params.serviceTag;

        // Prepare client for submission
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        // Get current computer
        const compFilter = new ComputerFilter();
        compFilter.serviceTags = [];
        compFilter.serviceTags.push(this.serviceTag);
        this.computer = (await ComputerService.Get(this.client, compFilter)).computers[0];

        // Get all rooms for select dropdown
        const roomFilter = new RoomFilter();
        roomFilter.include = [];
        roomFilter.include.push(RoomIncludes.Buildings);
        const response = await RoomService.Get(this.client, roomFilter);
        this.rooms = response.rooms;
        this.buildings = response.buildings;
    }

    async submit(){
        const response = await ComputerService.Update(this.client, this.serviceTag, this.computer);

        this.$router.go(0);
    }
}
