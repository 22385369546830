











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AuthButton extends Vue
{
  @Prop() click!: unknown;

  // Determine if user is authenticated
  isAuthenticated(): boolean {
    return this.$store.state.isAuthenticated;
  }
}
