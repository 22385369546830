
























































import { Building, BuildingFilter, BuildingService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ListBuildings extends Vue
{
    private client!: Client;

    private buildings: Building[] = [];

    async created(): Promise<void> {
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");

        this.buildings = (await BuildingService.Get(this.client, new BuildingFilter())).buildings;
    }

    async _delete(id: number): Promise<void> {
        // DELETE request
        await BuildingService.Delete(this.client, id);

        // Perform a GET request to ensure that all data are accurate (in case deletion failed)
        this.buildings = (await BuildingService.Get(this.client, new BuildingFilter())).buildings;
    }
}
