




























import { Vehicle, VehicleFilter, VehicleService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private id!: number;

    private client!: Client;

    private vehicle: Vehicle = new Vehicle();

    async created(){
        this.id = Number.parseInt(this.$route.params.id);

        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        const filter = new VehicleFilter();
        filter.ids = [];
        filter.ids.push(this.id);
        this.vehicle = (await VehicleService.Get(this.client, filter)).vehicles[0];
    }

    async submit(){
        const response = await VehicleService.Update(this.client, this.id, this.vehicle);

        this.$router.go(0);
    }
}
