


























import { Group, GroupService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
  private client!: Client;

  private group: Group = new Group();

  created() {
    const clientBuilder = new ClientBuilder();
    clientBuilder.server = process.env.VUE_APP_SERVER_URL;
    this.client = clientBuilder.openSession("");
  }

  async submit() {
    const response = await GroupService.Create(this.client, this.group);

    this.$router.push({name: "EditGroup", params: {id: "" + response.id}});
  }
}
