


























import { Building, BuildingFilter, BuildingService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private id!: number;

    private client!: Client;

    private building: Building = new Building();

    async created(){
        this.id = Number.parseInt(this.$route.params.id);

        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        const filter = new BuildingFilter();
        filter.ids = [];
        filter.ids.push(this.id);
        this.building = (await BuildingService.Get(this.client, filter)).buildings[0];
    }

    async submit(){
        const response = await BuildingService.Update(this.client, this.id, this.building);

        this.$router.go(0);
    }
}
