




























































import { Building, Client, ClientBuilder, Room, RoomFilter, RoomIncludes, RoomService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class ListRooms extends Vue
{
    private client!: Client;

    private rooms: Room[] = [];
    private buildings: Building[] = [];

    async created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");

        await this._get();
    }

    async _get(): Promise<void> {
        const filter = new RoomFilter();
        filter.include = [];
        filter.include.push(RoomIncludes.Buildings);
        const response = await RoomService.Get(this.client, filter);
        this.rooms = response.rooms;
        this.buildings = response.buildings;
    }

    async _delete(id: number): Promise<void> {
        // DELETE request
        await RoomService.Delete(this.client, id);

        // Perform a GET request to ensure that all data are accurate (in case deletion failed)
        await this._get();
    }
}
