












import { Component, Vue } from 'vue-property-decorator';
import { ClientBuilder, Client } from '@learningspaces/ls-client';
@Component
export default class Home extends Vue
{
    private client!: Client;

    created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");
    }
}
