

















import { Task, TaskFilter, TaskService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class extends Vue
{
    private id!: number;

    private client!: Client;

    private task: Task = new Task();

    async created(){
        this.id = Number.parseInt(this.$route.params.id);

        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        const filter = new TaskFilter();
        filter.ids = [];
        filter.ids.push(this.id);
        this.task = (await TaskService.Get(this.client, filter)).tasks[0];
    }

    async submit(){
        const response = await TaskService.Update(this.client, this.id, this.task);

        this.$router.go(0);
    }
}
