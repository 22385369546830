











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AuthRouterLinkButton extends Vue
{
  // Property for router-link destination
  @Prop() to!: unknown;

  // Determine if user is authenticated
  isAuthenticated(): boolean {
    return this.$store.state.isAuthenticated;
  }
}
