






































import { Building, Client, ClientBuilder, Computer, ComputerService, Room, RoomFilter, RoomIncludes, RoomService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private client!: Client;

    private computer: Computer = new Computer();

    private rooms: Room[] = [];
    private buildings: Building[] = [];

    async created() {
        // Prepare client for submission
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        // Get all rooms
        const filter = new RoomFilter();
        filter.include = [];
        filter.include.push(RoomIncludes.Buildings);
        const response = await RoomService.Get(this.client, filter);
        this.rooms = response.rooms;
        this.buildings = response.buildings;

        // Set roomId attribute to -1 to also set dropdown select to the
        // default "Select a room" option
        this.computer.roomId = -1;
    }

    async submit() {
        const response = await ComputerService.Create(this.client, this.computer);

        this.$router.push({name: "EditComputer", params: { serviceTag: response.serviceTag }});
    }
}
