


























import { Group, GroupFilter, GroupService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NewComponent extends Vue
{
  private id!: number;

  private client!: Client;

  private group: Group = new Group();

  async created() {
    this.id = Number.parseInt(this.$route.params.id);

    const clientBuilder = new ClientBuilder();
    clientBuilder.server = process.env.VUE_APP_SERVER_URL;
    this.client = clientBuilder.openSession("");

    const filter = new GroupFilter();
    filter.ids = [];
    filter.ids.push(this.id);
    this.group = (await GroupService.Get(this.client, filter)).groups[0];
  }

  async submit() {
    const response = await GroupService.Update(this.client, this.id, this.group);

    this.$router.go(0);
  }
}
