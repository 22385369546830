























import { Tysk, TyskService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private client!: Client;

    private tysk: Tysk = new Tysk();

    created() {
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");
    }

    async submit() {
        const response = await TyskService.Create(this.client, this.tysk);

        this.$router.push({name: "EditTysk", params :{ id: "" + response.id }});
    }
}
