

























































import { Building, Client, ClientBuilder, Computer, ComputerFilter, ComputerIncludes, ComputerService, Room } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class ListComputers extends Vue
{
    private client!: Client;

    private computers: Computer[] = [];
    private buildings: Building[] = [];
    private rooms: Room[] = [];

    async created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");

        await this._get();
    }

    async _get(): Promise<void> {
        const filter = new ComputerFilter();
        filter.include = [];
        filter.include.push(ComputerIncludes.Rooms);
        filter.include.push(ComputerIncludes.Buildings);

        const response = await ComputerService.Get(this.client, filter);
        this.computers = response.computers;
        this.buildings = response.buildings;
        this.rooms = response.rooms;
    }

    getRoomName(computer: Computer): string {
        const room = this.rooms.find(r => r.id == computer.roomId);

        if(room === undefined)
            return "";

        const building = this.buildings.find(b => b.id == room.buildingId);

        if(building === undefined)
            return "";

        return building.shortName + " " + room.shortName;
    }

    async _delete(serviceTag: string): Promise<void> {
        // DELETE request
        await ComputerService.Delete(this.client, serviceTag);

        // Perform a GET request to ensure that all data are accurate (in case deletion failed)
        await this._get();
    }
}
