






















































import { Client, ClientBuilder, Group, GroupFilter, GroupService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ListGroups extends Vue
{
    private client!: Client;

    private groups: Group[] = [];

    async created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");

        this.groups = (await GroupService.Get(this.client, new GroupFilter())).groups;
        this.groups = this.groups.sort((a,b) => a.sort < b.sort ? -1 : 1);
    }

    async _delete(id: number): Promise<void> {
        // DELETE request
        await GroupService.Delete(this.client, id);

        // Perform a GET request to ensure that all data are accurate (in case deletion failed)
        this.groups = (await GroupService.Get(this.client, new GroupFilter())).groups;
        this.groups = this.groups.sort((a,b) => a.sort < b.sort ? -1 : 1);
    }
}
