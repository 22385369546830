























import { Tysk, TyskFilter, TyskService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private id!: number;

    private client!: Client;

    private tysk: Tysk = new Tysk();

    async created(){
        this.id = Number.parseInt(this.$route.params.id);

        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        const filter = new TyskFilter();
        filter.ids = [];
        filter.ids.push(this.id);
        this.tysk = (await TyskService.Get(this.client, filter)).tysks[0];
    }

    async submit(){
        const response = await TyskService.Update(this.client, this.id, this.tysk);

        this.$router.go(0);
    }
}
