




















































import { Client, ClientBuilder, Task, TaskFilter, TaskService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class ListTasks extends Vue
{
    private client!: Client;

    private tasks: Task[] = [];

    async created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        // TODO ClientBuilder needs to be modified to where this is no longer
        //    required. The cookie handles session in this app. OpenSession
        //    should just be required if using token based authentication.
        this.client = clientBuilder.openSession("");

        this.tasks = (await TaskService.Get(this.client, new TaskFilter())).tasks;
    }

    async _delete(id: number): Promise<void> {
        // DELETE request
        await TaskService.Delete(this.client, id);

        // Perform a GET request to ensure that all data are accurate (in case deletion failed)
        this.tasks = (await TaskService.Get(this.client, new TaskFilter())).tasks;
    }
}
