







import { Component, Vue } from 'vue-property-decorator';
import Nav from '@/components/Nav.vue'

@Component({
    components: {
        Nav
    },
})
export default class App extends Vue
{
}
