

















import { Task, TaskService, Client, ClientBuilder } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class extends Vue
{
    private client!: Client;

    private task: Task = new Task();

    created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");
    }

    async submit(){
        const response = await TaskService.Create(this.client, this.task);

        this.$router.push({name: "EditTask", params :{ id: "" + response.id }});
    }
}
