




































import { Building, BuildingFilter, BuildingService, Client, ClientBuilder, Room, RoomService } from '@learningspaces/ls-client';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class NewComponent extends Vue
{
    private client!: Client;

    private room: Room = new Room();

    private buildings: Building[] = [];

    async created(){
        const clientBuilder = new ClientBuilder();
        clientBuilder.server = process.env.VUE_APP_SERVER_URL;
        this.client = clientBuilder.openSession("");

        // Get list of buildings
        const response = await BuildingService.Get(this.client, new BuildingFilter());
        this.buildings = response.buildings;

        // Set selection to "Select a building" (associated with value of -1)
        this.room.buildingId= -1;
    }

    async submit(){
        const response = await RoomService.Create(this.client, this.room);

        this.$router.push({name: "EditRoom", params :{ id: "" + response.id }});
    }
}
